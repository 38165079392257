@import '../../styles/imports';

.banner {
    section {
        min-height: 60vh;
        overflow-x: hidden;
        position: relative;

        .shadow-overlay {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 30%; // Adjust height to control the size of the shadow
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
            pointer-events: none; // Allow clicks to pass through to the banner
        }

        .bg-banner {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: 30% center;
            z-index: -1;
            position: absolute;
        }

        .button-container {
            position: absolute;
            bottom: 20px;
            padding: 0 50px;
            width: 100% !important;

            .btn {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px;
                border-radius: 30px;
                border-color: transparent;

                h3 {
                    margin: 0 !important;
                    color: $white;
                }

                &.whatsapp {
                    background-color: $whatsapp-green;
                }

                .svg-inline--fa {
                    margin-right: 8px;
                    font-size: 25px;
                }
            }
        }
    }

    @media (min-width: 1024px) {
        section {
            min-height: 50vh;
        }
    }
}