// _colors.scss

// Primary Colors
$primary-color: #FB6736;
$light-primary-color: #F38B68;
$secondary-color: #0D2D57;

// Accent Colors
$accent-color-1: #74E18F;
$accent-color-2: #F6D74A;

// Neutral Colors
$white: #ffffff;
$black: #000000;
$gray-light: #f8f9fa;
$gray-dark: #343a40;

// Other Colors
$background-color: #FB6736;
$text-color: #212529;
$whatsapp-green: #25D366;